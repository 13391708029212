import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect() {
    fetch(this.urlValue, { headers: { "X-Requested-With": "XMLHttpRequest" }})
      .then(response => response.text())
      .then(html => this.element.innerHTML = html);
  }
}
